.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.overlay {
  /* Customize the overlay's background color and other styles */
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Style for the modal */
.modal {
  /* Add your custom styles here */
  background-color: white; /* Adjust the background color to your preference */
  padding: 5px;
  border-radius: 8px;
  width: 50%; /* Set a default width for the modal */
  max-width: 90%; /* Set a maximum width for the modal */
  max-height: 80%; /* Set a maximum height for the modal content area */
  overflow-y: auto; /* Enable scrolling within modal content area */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Style for modal content */
.modal-content {
  flex-grow: 1; /* Allow modal content area to grow and take available space */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .modal {
    width: 100%; /* Adjust width for smaller screens */
  }
}



