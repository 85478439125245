@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #F8F9FA;
  border-top: 4px solid #000000;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ev-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ev-container {
  position: relative;
  width: 300px;
  height: 200px;
  background-color: #f8f9fa;
  border-radius: 10px;
  overflow: hidden;
}

.ev-body {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 220px;
  height: 120px;
  background-color: #343a40;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.ev-wheel {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: rotate-wheel 1s linear infinite;
}

.ev-body-shape {
  position: absolute;
  top: 20px;
  left: 75px;
  width: 70px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 5px;
}

.ev-window {
  position: absolute;
  top: 30px;
  left: 170px;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
}

.thank-you-message {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

@keyframes rotate-wheel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}





